<template>
  <section>
    <b-container class="pt-5 pb-2">
      <b-row class="d-flex justify-content-center mb-3 p-2">
        <curva-title :title="$t('about.team')"/>
      </b-row>
      <b-row class="mb-5 d-flex justify-content-around">
        <b-col lg="2" sm="3" v-for="(member, key) in team" :key="key">
          <div
              class="circle-img circle-img-shade mb-3">
            <img class="w-100" :src="member.img" @error="replaceByDefault"/>
          </div>
          <div class="text-center">
            <p>{{ member.name }}</p>
            <label class="text-secondary">{{ member.job }}</label>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  props: {
    team: { type: Array }
  },
  data () {
    return {
      /* team: [
        {
          name: 'Yousri',
          title: 'Owner',
          img: require('@/assets/images/curva/Yousri.png')
        },
        {
          name: 'Eslam Magdi',
          title: 'Product Manager',
          img: require('@/assets/images/curva/Eslam.png')
        },
        {
          name: 'Ahmed Sayed',
          title: 'Team Manager',
          img: require('@/assets/images/curva/Ahmed.png')
        }
      ] */
      defaultImage: require('@/assets/images/user/default-user-image.png')
    }
  },
  methods: {
    replaceByDefault (e) {
      e.target.src = this.defaultImage
    }
  }
}
</script>
