<template>
  <section>
    <b-container class="mb-4 pt-4 text-initial">
      <curva-title :title="$t('about.title')"/>
      <b-row class="justify-content-between mt-3">
        <b-col lg="7">
          <p v-html="about" class=" text-initial"></p>
        </b-col>
        <b-col lg="4" cols="10">
<!--          <div class="our-story-img-container">-->
            <div class="our-story-img d-flex align-items-center justify-content-center">
              <img :src="ourStory"/>
            </div>
<!--          </div>-->
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
export default {
  props: {
    about: { type: String }
  },
  data () {
    return {
      ourStory: require('@/assets/images/curva/our-story.png')
    }
  }
}
</script>
<style>
.our-story-img {
  width: 116%;
  height: 100%;
}
.our-story-img img {
  width: 90%;
  height: 90%;
}
@media screen and (max-width:500px) {
  .our-story-img {
    width: 117%;
    height: 99%;
  }
  .our-story-img img {
    width: 90%;
    height: 90%;
  }
}
</style>
